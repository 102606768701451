import React from "react";
import styled from "../app/styled";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import Image from "../base_components/Image";

const Service = (props: {
  image: string;
  name: string;
  description: string;
  secondDescription?: string;
}) => {
  return (
    <ServiceBox>
      <ThumbnailImage>
        <Image size={300} src={props.image} alt={`Služba ` + props.name} />
      </ThumbnailImage>
      <ServiceName>{props.name}</ServiceName>
      <Text>{props.description}</Text>
      {props.secondDescription && (
        <>
          <br />
          <Text>{props.secondDescription}</Text>
        </>
      )}
    </ServiceBox>
  );
};

const ServiceBox = styled(Box)`
  padding: ${({ theme }) => theme.spacing.default};
  flex: 0 0 30%;
  align-items: center;
  justify-content: normal;

  @media ${({ theme }) => theme.device.tablet} {
    flex: 0 0 50%;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex: 0 0 100%;
  }
`;

const ThumbnailImage = styled(Box)`
  width: 300px;
`;

const ServiceName = styled(Text).attrs({ as: "h3", color: "orange" })`
  align-self: center;
  padding: ${({ theme }) => theme.spacing.small};
`;

export default Service;
