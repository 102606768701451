import React, { useState } from "react";
import Page from "../components/Page";
import styled from "../app/styled";
import Category from "../components/Category";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [isSentRequest, setIsSentRequest] = useState(false);

  if(isSentRequest === false) {
    setIsSentRequest(true);

    fetch(`/api/categories`, {
      method: "GET",
    }).then(response => response.json()).then(data => {
      if(data && categories.length === 0) {
        setCategories(data.data);
      }
    })
  }

  if (categories.length === 0) {
    return (
      <Page
        title="Kategorie"
        isLoading={true}
        isError={false}
      />
    );
  }

  return (
    <Page title="Kategorie">
      <CategoriesGrid>
        {categories?.map((category: any) => (
          <Category
            name={category.name}
            slug={category.slug}
            id={category.slug}
            image={category.image}
          />
        ))}
      </CategoriesGrid>
    </Page>
  );
};

const CategoriesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
`;

export default CategoriesPage;
