import React from "react";
import { ThemeProvider } from "./app/styled";
import theme from "./app/theme";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutPage from "./pages/about";
import ProductPage from "./pages/product";
import ProductsPage from "./pages/products";
import CategoriesPage from "./pages/categories";
import ServicePage from "./pages/service";
import ServicesPage from "./pages/services";
import ReferencesPage from "./pages/references";
import IndexPage from "./pages/index";
import ContactsPage from "./pages/contacts";
import GlobalStyle from "./app/global";
import { CloudimageProvider } from "react-cloudimage-responsive";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import Analytics from 'react-router-ga';
import BlizzardPage from "./pages/blizzard";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "http://144.91.65.179:4000/graphql",
  }),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <CloudimageProvider
        config={{
          token: "acwaqswnmo",
          imgLoadingAnimation: false,
          lazyLoading: false,
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Analytics id={"UA-173407163-1"}>
              <Switch>
                <Route exact path="/" component={IndexPage} />
                <Route exact path="/o-nas" component={AboutPage} />
                <Route exact path="/produkt/:slug" component={ProductPage} />
                <Route exact path="/produkty" component={CategoriesPage} />
                <Route exact path="/produkty/:slug" component={ProductsPage} />
                <Route exact path="/sluzby" component={ServicesPage} />
                <Route exact path="/sluzby/:slug" component={ServicePage} />
                <Route exact path="/reference" component={ReferencesPage} />
                <Route exact path="/kontakt" component={ContactsPage} />
                <Route exact path="/blizzard" component={BlizzardPage} />
              </Switch>
            </Analytics>
          </Router>
        </ThemeProvider>
      </CloudimageProvider>
    </ApolloProvider>
  );
}

export default App;
