import styled from "../app/styled";

const Button = styled.button<{selfAlign?: string}>`
    align-self: ${props => props.selfAlign ? props.selfAlign : "inherit"};
    padding: ${({theme}) => theme.spacing.default} ${({theme})=> theme.spacing.small};
    text-transform: uppercase;
    background: ${({theme})=>theme.colors.white};
    border: 1px solid ${({theme}) => theme.colors.grey};
    color: ${({theme}) => theme.text.color};
    transition: all 300ms;
    cursor: pointer;

    &:hover {
        color: ${({theme}) => theme.colors.orange};
        border: 1px solid ${({theme}) => theme.colors.orange};
    }

    &:focus {
        color: ${({theme}) => theme.colors.orange};
        border: 1px solid ${({theme}) => theme.colors.orange};
        outline: none;
    }
`;

export default Button