import React from "react";
import Page from "../components/Page";
import Box from "../base_components/Box";
import Service from "../components/Service";
import styled from "../app/styled";

const ServicesPage = () => {
  return (
    <Page title="Služby">
      <Services>
        <Service
          name="Projektování"
          image="https://res.cloudinary.com/dbisoc34u/image/upload/v1595337277/projektovani_noc6zl.jpg"
          description="Projektujeme nové stavby, včetně podrobné dokumentace a všech potřebných výpočtů, včetně navržení rozmístění sněhových děl a čerpacích stanic."
        />
        <Service
          name="iSNO – ovládací prvky a automatizace"
          image="https://res.cloudinary.com/dbisoc34u/image/upload/v1595337276/iSNO_1_gwv6ry.jpg"
          description="Systém iSno Controls and Automation je páteří operací zasněžování a spoléhají na ně lyžařské oblasti všech velikostí, od rodinných až po některé z největších a nejvýkonnějších systémů na světě."
          secondDescription="iSNO stanovilo standardy pro řízení strojovny a automatizační software a je navrženo tak, aby vyhovovalo většině rozpočtů pro zasněžování. Střediska mohou začít monitorováním čerpadel, kompresorů a meteorologických stanic a poté postupně navyšovat na pokročilejší řízení, automatizaci a hlášení. Provozovatelé středisek mohou dohlížet na všechny své zasněžovací operace pomocí systémových dat v reálném čase a vlastních hlášení poskytovaných softwarem iSNO, a to i v několika resortech současně. iSNO je páteří pro zasněžovací provozy, na které se spoléhají lyžařská střediska všech velikostí, od rodinných až po některé z největších a nejvýkonnějších systémů na světě."
        />
        <Service
          name="Servis"
          image="https://res.cloudinary.com/dbisoc34u/image/upload/v1595337272/servis_vlcic3.jpg"
          description="Máme vlastní zkušený tým techniků specializovaných na lanové dráhy, vleky a zasněžovací techniku, kteří každoročně prochází školením nových produktů a jsou připraveni řešit situace, se kterými si neporadíte."
        />
      </Services>
    </Page>
  );
};

const Services = styled(Box).attrs({ isRow: true })`
  @media ${({ theme }) => theme.device.tablet} {
    align-items: center;
    justify-content: center;
  }
`;

export default ServicesPage;
