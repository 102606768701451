import styled from "../app/styled";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import { Link } from "react-router-dom";
import React from "react";
import ImageCropped from "../base_components/ImageCropped";

const Product = ({ data }: any) => (
  <Link to={`/produkt/${data.slug}`}>
    <Container key={data.id}>
      <ExtendedImage
        name={data.name}
        src={data?.photos[data.mainPhoto ? data.mainPhoto - 1 : 0]?.link}
        size={600}
        ratio={1}
        position={"TOP"}
      />
      <ContentContainer>
        <Name>{data.name}</Name>
      </ContentContainer>
    </Container>
  </Link>
);

const Name = styled(Text)`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing.small}px;
  font-size: ${({ theme }) => theme.fontSize.bigger}px;
  cursor: pointer;
  justify-content: center;

  @media only screen and (max-width: 900px) {
    font-size: ${({ theme }) => theme.fontSize.medium}px;
  }
`;

const Container = styled(Box)`
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: 100%;
  padding-bottom: ${(props) => props.theme.spacing.default}px;

  &:hover ${Name} {
    text-decoration: underline solid ${({ theme }) => theme.colors.orange};
  }
`;

const ExtendedImage = styled(ImageCropped)`
  width: 100%;
  overflow: hidden;
`;

const ContentContainer = styled(Box)`
  flex: 1 1 auto;
  justify-content: space-between;
`;

export default Product;
