import React, { useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import styled from "../app/styled";
import Box from "../base_components/Box";
import Image from "../base_components/Image";
import { IoMdMenu, IoMdClose } from "react-icons/io";

const Header = ({ match } : RouteComponentProps) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  console.log(match.path);
  return (
    <NavBar>
      <Link to="/">
        <Image
          src="https://noho-frontend.now.sh/assets/images/logo.jpg"
          alt="NOHO logo"
          noLazy
          size={180}
        />
      </Link>

      <Nav isMobileMenuOpen={isMobileMenuOpen}>
        <NavLink to={`/o-nas`} isActive={match.path === '/o-nas' && true}>O nás</NavLink>
        <NavLink to={`/produkty`} isActive={match.path === '/produkty' && true}>produkty</NavLink>
        <NavLink to={`/sluzby`} isActive={match.path === '/sluzby' && true}>Služby</NavLink>
        
        <NavLink to={`/blizzard`} isActive={match.path === '/blizzard' && true}>Blizzard Factory</NavLink>
        <NavLink to={`/kontakt`} isActive={match.path === '/kontakt' && true}>Kontakt</NavLink>
      </Nav>

      <ToggleWrapper>
        <Box onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
          {isMobileMenuOpen ? <IoMdClose size={30} /> : <IoMdMenu size={30} />}
        </Box>
      </ToggleWrapper>
    </NavBar>
  );
};

const NavBar = styled(Box).attrs({ isRow: true })`
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  align-content: center;
  align-items: center;
  z-index: 999;
  background: ${({ theme }) => theme.colors.white};
  padding: 0 ${({ theme }) => theme.spacing.medium};
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.08);
`;

const Nav = styled(Box).attrs({ isRow: true })<{ isMobileMenuOpen?: boolean }>`
  text-align: center;
  justify-content: space-evenly;
  transition: all 300ms;

  @media only screen and (max-width: 850px) {
    display: ${(props) => (props.isMobileMenuOpen ? "flex" : "none")};
    background: ${({ theme }) => theme.colors.white};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const NavLink = styled(Link)<{
  noMargin?: boolean;
  isTransparent?: boolean;
  isActive?: boolean;
}>`
  cursor: pointer;
  color: ${(props) => props.isActive ? ({ theme }) => theme.colors.orange : ({ theme }) => theme.colors.grey};
  text-transform: uppercase;
  transition: all 300ms;
  text-decoration: none;
  margin-left: ${(props) => (props.noMargin ? 0 : props.theme.spacing.max)};

  @media only screen and (max-width: 850px) {
    color: ${({ theme }) => theme.colors.black};
    padding: ${(props) => (props.noMargin ? 0 : props.theme.spacing.default)};
    margin-left: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

const ToggleWrapper = styled(Box)`
  cursor: pointer;
  flex-direction: row;
  display: none;

  @media only screen and (max-width: 850px) {
    display: flex;
  }
`;

export default withRouter(Header);
