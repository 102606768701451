import styled from '../app/styled';

const Box = styled.div<{isRow?: boolean}>`
    display: flex;
    flex-direction: ${props => props.isRow ? 'row' : 'column'};
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export default Box;