import React from "react";
import styled from "../app/styled";
import SpinnerLoader from "./Spinner";
import Header from "./Header";
import Footer from "./Footer";
import Box from "../base_components/Box";
import { Helmet } from "react-helmet";
import Heading from "./Heading";

const Page = (props: {
  title: string;
  description?: string;
  children?: any;
  isLoading?: boolean;
  isError?: boolean;
  ownHeading?: boolean;
}) => {

  return (
    <Body>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title} | NOHOSNOW</title>
        <meta
          name="description"
          content={
            props.description
              ? props.description
              : "Komplexní řešení pro horská střediska NoHo Snow"
          }
        />
      </Helmet>

      <Container>
        {!props.ownHeading && (
          <Box>
            <Heading>{props.title}</Heading>
          </Box>
        )}
        {props.isLoading && <SpinnerLoader />}
        {!props.isError && props.children}
      </Container>

      <Footer />
    </Body>
  );
};

const Body = styled(Box)`
  min-height: calc(100vh - 70px);
  justify-content: space-between;

  & > *:first-child {
    height: ${(props) => props.theme.header.desktopHeight}px !important;
    flex: 0 0 ${(props) => props.theme.header.desktopHeight}px !important;
  }
`;

const Container = styled(Box)`
  width: 100%;
  position: relative;
  margin: 0 auto;
  top: 70px;
  padding: 0 ${({ theme }) => theme.spacing.default};
  max-width: 1400px;
  display: block;
`;

export default Page;
