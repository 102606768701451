import React from "react";
import Page from "../components/Page";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { products } from "../temp/consts";
import Breadcrumbs from "../components/Breadcrumbs";

const ServicePage = ({ match }: RouteComponentProps<{ slug: string }>) => {
  console.log(match);

  const product = products.data.find(
    (product): any => product.slug === match.params.slug
  );
  return (
    <Page title={product!.name}>
      <Breadcrumbs
      //@ts-ignore
        links={[
          { name: "Produkty", url: "/sluzby" },
          {name: product?.name,
          url: `/produkt/${product?.slug}`},
        ]}
      />
    </Page>
  );
};

export default withRouter(ServicePage);
