import styled from "../app/styled";
import Lazyload from "./Lazyload";
import React from "react";

export const cloudimgBaseUrl = 'https://apoxmpcimo.cloudimg.io';

const Image = (props: {
  src: string;
  alt: string;
  size?: number;
  hoverScale?: boolean;
  itemProp?: string;
  noLazy?: boolean;
  lazyHeight?: number;
}) => (
  <Lazyload
    height={props.lazyHeight ? props.lazyHeight : 200}
    disabled={props.noLazy}
  >
    
      <Img
        src={`${cloudimgBaseUrl}/v7/${props.src}?width=${props.size ? props.size : 500}`}
        alt={props.alt}
        itemProp={props.itemProp}
        hoverScale={props.hoverScale}
      />
  </Lazyload>
);

const Img = styled.img<{ hoverScale?: boolean }>`
  width: 100%;
  height: auto;
  line-height: 0;
  transition: all 0.5s ease;

  &:hover {
    transform: ${({ hoverScale }) => (hoverScale ? "scale(1.1)" : null)};
    opacity: ${({ hoverScale }) => (hoverScale ? 0.9 : 1)};
  }
`;

export default Image;
