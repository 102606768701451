import { createGlobalStyle } from "./styled";

const GlobalStyle = createGlobalStyle`
  
  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }

  body {
    box-sizing: border-box;
    font-family: sans-serif;
  }

  a {
    color: ${({ theme }) => theme.colors.grey};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`;

export default GlobalStyle;
