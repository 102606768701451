import React from "react";
import Page from "../components/Page";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import Reference from "../components/Reference";
import styled from "../app/styled";

const ReferencesPage = () => {
  return (
    <Page title="Reference">
      <Box>
        <StyledText>
          Reference našich zákazníků.
        </StyledText>
      </Box>
      <Box>
        <Reference
          key={1}
          name="Firma 1"
          image="https://via.placeholder.com/250"
          date="10.5.2020"
          text="Text is optional. The default is the image dimensions in pixels. You can use A-Z characters, upper or lowercase, numbers, and most symbols. Spaces are specified with a plus sign (+). For example…"
        />
        <Reference
          key={2}
          name="Firma 1"
          image="https://via.placeholder.com/250"
          date="10.5.2020"
          text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Quisque porta. Proin mattis lacinia justo. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Etiam commodo dui eget wisi. Aliquam erat volutpat. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Fusce consectetuer risus a nunc. Aliquam erat volutpat. Duis ante orci, molestie vitae vehicula venenatis, tincidunt ac pede. Sed vel lectus. Donec odio tempus molestie, porttitor ut, iaculis quis, sem. In laoreet, magna id viverra tincidunt, sem odio bibendum justo, vel imperdiet sapien wisi sed libero.          "
        />
        <Reference
          key={3}
          name="Firma 1"
          image="https://via.placeholder.com/250"
          date="10.5.2020"
          text="Text is optional. The default is the image dimensions in pixels. You can use A-Z characters, upper or lowercase, numbers, and most symbols. Spaces are specified with a plus sign (+). For example…"
        />
        <Reference
          key={4}
          name="Firma 1"
          image="https://via.placeholder.com/250"
          date="10.5.2020"
          text="Text is optional. The default is the image dimensions in pixels. You can use A-Z characters, upper or lowercase, numbers, and most symbols. Spaces are specified with a plus sign (+). For example…"
        />
        <Reference
          key={5}
          name="Firma 1"
          image="https://via.placeholder.com/250"
          date="10.5.2020"
          text="Text is optional. The default is the image dimensions in pixels. You can use A-Z characters, upper or lowercase, numbers, and most symbols. Spaces are specified with a plus sign (+). For example…"
        />
      </Box>
    </Page>
  );
};

const StyledText = styled(Text).attrs({color: "grey", isItalic: true})`
  align-self: center;
`;

export default ReferencesPage;
