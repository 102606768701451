import React from "react";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import styled from "../app/styled";
import { IoLogoFacebook, IoLogoInstagram, IoIosMail } from "react-icons/io";

const Footer = () => {
  return (
    <FooterRow>
      <Text>&copy; NoHo Prague 2024</Text>
      <Box isRow>
        <a href="http://www.facebook.com/NoHo-Snow">
          <IoLogoFacebook size={30} />
        </a>
        <a href="http://www.instagram.com/nohosnow">
          <IoLogoInstagram size={30} />
        </a>
      </Box>
      <Box>
        <a href="mailto:info@nohosnow.com">
          <IoIosMail size={30} />
        </a>
      </Box>
    </FooterRow>
  );
};

const FooterRow = styled(Box).attrs({ isRow: true })`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.default};
  padding: ${({ theme }) => theme.spacing.big};
  background: ${({ theme }) => theme.colors.lightGrey};
  box-shadow: 0 -4px 6px 0 hsla(0, 0%, 0%, 0.08);
  bottom: -70px;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.default};
  }
`;

export default Footer;
