import LazyLoad from 'react-lazyload';
import React from 'react';

type Props = {
  height: number;
  children?: any;
  disabled?: boolean;
};

const Lazy = ({ children, height, disabled }: Props) => {
  if (disabled) {
    return children;
  }

  return <LazyLoad height={height}>{children}</LazyLoad>;
};

export default Lazy;
