import React from "react";
import styled from "../app/styled";
import Box from "../base_components/Box";
import Image from "../base_components/Image";
import Text from "../base_components/Text";

const Reference = (props: {
  name: string;
  image: string;
  text: string;
  date: string;
}) => {
  return (
    <ReferenceRow>
      <ThumbnailImage>
        <Image src={props.image} alt={`Reference od firmy ` + props.name} />
      </ThumbnailImage>
      <TextBox>
        <ReferenceName>{props.name}</ReferenceName>
        <ReferenceText>{props.text}</ReferenceText>
        <Date>{props.date}</Date>
      </TextBox>
    </ReferenceRow>
  );
};

const ReferenceRow = styled(Box).attrs({ isRow: true })`
  margin: ${({ theme }) => theme.spacing.default} 0;
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2);
  flex-wrap: nowrap;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const ThumbnailImage = styled(Box)`
  width: 250px;
`;

const TextBox = styled(Box)`
  padding: ${({ theme }) => theme.spacing.default};
  width: calc(100% - 250px);

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;

const ReferenceName = styled(Text).attrs({
  color: "orange",
  fontBolder: true,
  as: "h3",
})`
  @media ${({ theme }) => theme.device.tablet} {
    align-self: center;
  }
`;

const ReferenceText = styled(Text)``;

const Date = styled(Text).attrs({ color: "grey" })`
  @media ${({ theme }) => theme.device.tablet} {
    align-self: center;
  }
`;

export default Reference;
