import ReactMarkdown from 'react-markdown';
import styled from '../app/styled';
import React from 'react';

const StyledMarkdown = (props: { source: any; itemProp?: string; headingColor?: string; noLists?: boolean }) => {
  return (
    <Content itemProp={props.itemProp} headingColor={props.headingColor} noLists={props.noLists}>
      <ReactMarkdown
        escapeHtml={false}
        source={props.source
          .replace('<center>', '<p style="text-align: center">')
          .replace('</center>', '</p>')
          .replace('<iframe', '<div><iframe style="width:100%; height: 500px;"')
          .replace('</iframe>', '</iframe></div>')}
        linkTarget={'_blank'}
      />
    </Content>
  );
};

const Content = styled.div<{ headingColor?: string, noLists?: boolean }>`
  h1 {
    margin: 0;
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing.default}px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${props => (props.headingColor ? props.headingColor : ({ theme }) => theme.colors.green)};
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: ${({ theme }) => theme.fontSize.normal}px;
    font-family: ${({ theme }) => theme.text.fontFamily};
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  h2 {
    margin: 0;
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing.default};
    font-weight: 800;
    text-transform: uppercase;
    color: ${props => (props.headingColor ? props.headingColor : ({ theme }) => theme.colors.green)};
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: calc(${({ theme }) => theme.fontSize.normal}px + 2px);
    font-family: ${({ theme }) => theme.text.fontFamily};
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  h3 {
    margin: 0;
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing.default};
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => (props.headingColor ? props.headingColor : ({ theme }) => theme.colors.green)};
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: ${({ theme }) => theme.fontSize.normal}px;
    font-family: ${({ theme }) => theme.text.fontFamily};
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  p {
    padding-top: ${({ theme }) => theme.spacing.small};
    margin: 0;
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: ${({ theme }) => theme.fontSize.normal}px;
    font-family: ${({ theme }) => theme.text.fontFamily};
    color: ${({ theme }) => theme.colors.black};
    font-feature-settings: 'pnum' on, 'lnum' on;
    width: 100%;
    text-align: justify;
  }

  a {
    margin: 0;
    padding: 0;
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: ${({ theme }) => theme.fontSize.normal}px;
    font-family: ${({ theme }) => theme.text.fontFamily};
    color: ${({ theme }) => theme.colors.green} !important;
    font-feature-settings: 'pnum' on, 'lnum' on;
    cursor: pointer;
    display: inline;

    &:hover {
      text-decoration: underline solid ${({ theme }) => theme.colors.pink};
    }

    & strong {
      color: ${props => (props.headingColor ? props.headingColor : ({ theme }) => theme.colors.green)} !important;
    }
  }

  strong {
    font-weight: bold;
    display: inline;
    margin: 0;
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: ${({ theme }) => theme.fontSize.normal}px;
    font-family: ${({ theme }) => theme.text.fontFamily};
    color: ${props => (props.headingColor ? props.headingColor : ({ theme }) => theme.colors.black)};
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  img {
    display: block;
    height: auto;
    width: 75% !important;
    margin: 0 auto;
    padding-bottom: ${({ theme }) => theme.spacing.small};
  }

  ul {
    list-style-type: ${props => props.noLists ? "none" : "disc"};
    padding-left: ${({ theme }) => theme.spacing.default};
  }

  li {
    display: list-item;
    font-size: ${({ theme }) => theme.fontSize.normal}px;
    line-height: ${({ theme }) => theme.text.lineHeight};
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.text.fontFamily};
  }

  table {
    display: table;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: ${({ theme }) => theme.colors.green};
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: ${({ theme }) => theme.colors.black};
  }

  td {
    display: table-cell;
    vertical-align: inherit;
  }
`;

export default StyledMarkdown;
