import React, { useState } from "react";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import styled from "../app/styled";
import { FaSnowflake } from "react-icons/fa";
import { Link } from "react-router-dom";

const CategoriesRow = () => {
  const [categories, setCategories] = useState([]);
  const [isSentRequest, setIsSentRequest] = useState(false);

  if (isSentRequest === false) {
    setIsSentRequest(true);
    fetch(`/api/categories`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (categories.length === 0) {
          setCategories(data.data);
        }
      });
  }

  return (
    <Row>
      {categories?.map((category: any) => (
        <Link key={category.id} to={`/produkty/${category.slug}`}>
          <Category>
            <FaSnowflake size={30} color="#ef7b1a" />
            <Name>{category.name}</Name>
          </Category>
        </Link>
      ))}
    </Row>
  );
};

const Row = styled(Box).attrs({ isRow: true })`
  padding: ${({ theme }) => theme.spacing.default} 0;
  margin: 0 auto;
  justify-content: center;
`;

const Category = styled(Box)`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.small}
    ${({ theme }) => theme.spacing.default};
  transition: all 300ms;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
    border: 1px solid ${({ theme }) => theme.colors.orange};
  }
`;

const Name = styled(Text).attrs({ color: "orange" })``;

export default CategoriesRow;
