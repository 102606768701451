import {Field} from "formik";
import styled from "../app/styled";

const StyledField = styled(Field)<{height: number}>`
    width: 100%;
    height: ${props => props.height ? props.height : 40}px;
    border: 1px solid ${({theme})=> theme.colors.grey};
    padding: ${({theme})=>theme.spacing.small};
    margin: ${({theme})=>theme.spacing.small} 0;
    box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);

    &:focus {
        border-color: ${({theme})=>theme.colors.orange};
        outline: none;
    }
`;

export default StyledField;