import React, { useState } from "react";
import Page from "../components/Page";
import Text from "../base_components/Text";
import styled from "../app/styled";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CategoriesRow from "../components/CategoriesRow";
import Product from "../components/Product";

const ProductsPage = ({ match }: RouteComponentProps<{ slug?: string }>) => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("Načítám produkty");
  const [categorySlug, setCategorySlug] = useState(match.params.slug);
  const [isSentRequest, setIsSentRequest] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  if (categorySlug !== match.params.slug) {
    setProducts([]);
    setCategorySlug(match.params.slug);
    setIsSentRequest(false);
    setShowLoading(true);
  }

  if (isSentRequest === false) {
    setIsSentRequest(true);
    fetch(`/api/categories?slug=${categorySlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setCategory(data.data.name);
        if (products.length === 0) {
          setProducts(data.data.products);
        }
      })
      .finally(() => setShowLoading(false));
  }

  if (showLoading) {
    return <Page title="Načítání" isError={false} isLoading={true} />;
  }

  if (products.length === 0) {
    return (
      <Page title={category}>
        <CategoriesRow />
        <NoContent>V této kategorii ještě nemáme žádné produkty.</NoContent>
      </Page>
    );
  }

  return (
    <Page title={category}>
      <CategoriesRow />
      <ProductsGrid>
        {products?.map((product: any) => (
          <Product key={product.id} data={product} />
        ))}
      </ProductsGrid>
    </Page>
  );
};

const ProductsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.default}px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;

  @media only screen and (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const NoContent = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.bigger};
  color: ${(props) => props.theme.colors.orange};
`;

export default withRouter(ProductsPage);
