import React from "react";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import { Link } from "react-router-dom";
import styled from "../app/styled";

const Breadcrumbs = (props: { links: [{ name: string; url: string }] }) => {
  console.log(props);
  return (
    <Box isRow>
      <Text>
        {props.links.map((link, index) => (
          <>
            <Link to={link.url}>
              <StyledText isLast={index === props.links.length - 1}>
                {link.name}
              </StyledText>
            </Link>
            {index !== props.links.length - 1 && <Text>&nbsp;/&nbsp;</Text>}
          </>
        ))}
      </Text>
    </Box>
  );
};

const StyledText = styled(Text)<{ isLast?: boolean }>`
  color: ${(props) =>
    props.isLast ? props.theme.colors.orange : props.theme.text.color};
  font-weight: ${(props) =>
    props.isLast
      ? props.theme.text.fontWeightBold
      : props.theme.text.fontWeightNormal};

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export default Breadcrumbs;
