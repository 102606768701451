import styled from "../app/styled";
import { colors } from "../app/theme";

const Break = styled.hr<{ color?: keyof typeof colors; width?: number }>`
  margin: ${({ theme }) => theme.spacing.default} 0;
  margin-bottom: ${({ theme }) => theme.spacing.big};
  padding: 0;
  line-height: ${({ theme }) => theme.text.lineHeight};
  display: flex;
  color: ${(props) =>
    props.color
      ? ({ theme }) => theme.colors[props.color!]
      : props.theme.text.color};
  height: ${({ theme }) => theme.fontSize.supermini}px;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
`;

export default Break;
