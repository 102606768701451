import styled from "../app/styled";
import Text from "../base_components/Text";
import Box from "../base_components/Box";
import React from "react";
import Break from "../base_components/Break";

const Heading = (props: {children: any}) => (
  <Box>
    <HeadingText>{props.children}</HeadingText>
    <Break color={"grey"} style={{ alignSelf: "center" }} width={50} />
  </Box>
);

const HeadingText = styled(Text).attrs({ color: "orange", as: "h1"})`
  padding-top: ${({ theme }) => theme.spacing.big};
  font-size: ${({ theme }) => theme.fontSize.big}px;
  align-self: center;
  text-align: center;
`;

export default Heading;
