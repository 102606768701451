export const categories = {
  categories: [
    {
      name: "Sněžná děla",
      slug: "snezna-dela",
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-home-featured-products-fans-hkd-snowmakers.png",
    },
    {
      name: "Vzduch / Voda",
      slug: "vzduch-voda",
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-products-impulse-r5-hkd-snowmakers.png",
    },
    {
      name: "Hadice",
      slug: "hadice",
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-add-ons-hose-hkd-snowmakers.png",
    },
    {
      name: "Hydranty",
      slug: "hydranty",
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-products-klik-automatic-hkd-snowmakers.png",
    },
    {
      name: "Příslušenství",
      slug: "prislusenstvi",
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-add-ons-traditional-snowmaking-hydrants-hkd-snowmakers.png",
    },
  ],
};

export const products = {
  data: [
    {
      name: "Diablo",
      slug: "diablo",
      category: {
        slug: "snezna-dela",
        name: "Sněžná děla",
      },
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-diablo-main-hkd-snowmakers.png",
      text:
        "HKD Diablo je tažný kůň v naší sestavě sněžných děl. Diablo je zkonstruováno pro jednoduchost a účinnost a je schopné průtoku vody až 250 GPM s projekcí přesahující 225 stop. Diablo využívá naši technologii Constant Cure Technology (CCT) na rozdíl od jiných ventilátorových zbraní na trhu. Unikátní konstrukce středové trysky poskytuje hladkou křivku výkonu s nekonečným nastavením průtoku vody a kvality sněhu vs. krokové funkce všech ostatních technologií ventilátoru. To maximalizuje produkci sněhu za všech teplot. Diablo je zasněžování ve velkém měřítku.",
      photos: [
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img1-hkd-snowmakers.jpg",
        },
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img2-hkd-snowmakers.jpg",
        },
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img3-hkd-snowmakers.jpg",
        },
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img4-hkd-snowmakers.jpg",
        },
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img5-hkd-snowmakers.jpg",
        },
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img6-hkd-snowmakers.jpg",
        },
        {
          url:
            "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/gallery-diablo-img7-hkd-snowmakers.jpg",
        },
      ],
      videos: [
        { url: "https://vimeo.com/80304560" },
        { url: "https://www.youtube.com/watch?v=aV7SyWAnbTc" },
      ],
    },
    {
      name: "Halo",
      slug: "halo",
      category: {
        slug: "snezna-dela",
        name: "Sněžná děla",
      },
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-halo-main-hkd-snowmakers.png",
      text:
        "HKD Halo je inovativní konstrukce s více tryskami s ventilátorem, která se daří v mezních teplotách a nízkém tlaku vody. Halo dělá nebeský sníh za všech podmínek.<br />HKD Halo má 8 průtoků v rozsahu od 15 do 150 GPM a může být provozován s tlakem vody až 120 PSI. Halo je k dispozici v manuálním, poloautomatickém nebo plně automatickém provedení ve spojení s pohonem Minion Hydrant a síťovým připojením.<br />Kvalita a jednoduchost sněhu je jádrem Halo. Jeho modulární konstrukce je plug and play s fanoušky HKD současné generace. Jednoduše vyjměte modul Diablo a zapojte Halo - jedná se o práci pro jednu osobu. Software HKD rozpozná změnu technologie a provede nezbytné přizpůsobení výkonových křivek sněhové zbraně. Je to jako mít dva fanoušky v jednom.",
    },
    {
      name: "HKD Blue",
      slug: "hkd-blue",
      category: {
        slug: "snezna-dela",
        name: "Sněžná děla",
      },
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-products-hkd-blue-hkd-snowmakers.png",
      text:
        "HKD Blue je ekologická divize HKD Snowmakers. HKD Blue používá technologii rozprašování vody pro potlačení prachu v průmyslu demolice, manipulace s materiálem, agregátu a recyklace. Mezi další aplikace patří regulace kouře, odpařování a potlačení ohně.",
    },
    {
      name: "Klik Auto",
      slug: "klik-auto",
      category: {
        slug: "hydranty",
        name: "Hydranty",
      },
      image:
        "https://www.hkdsnowmakers.com/wp-content/uploads/2019/10/thumb-klik-auto-main-hkd-snowmakers.png",
      text:
        "Automatický hydrant HKD KLIK je navržen pro provoz všech verzí věží HKD. Schopnost vzdáleně spouštět, zastavovat a ovládat vaši technologii zasněžování má v dnešním světě, kde jsou teploty zasněžování nepředvídatelné, obrovskou hodnotu a je těžké najít zkušenou práci. KLIK Auto Hydrants zlepšují užitečnost a efektivitu zasněžování o více než 200% ve srovnání s použitím tradičních hydrantů a hadic. Tyto zisky jsou ještě výraznější, když čelíte více startům a zastávkám na začátku sezóny a zpočátku otevíráte kritický terén.<br />Automatizační software HKD KLIK využívá křivky výkonu sněhových pistolí pro různé produkty HKD spolu s informacemi o počasí v reálném čase k dosažení požadované kvality sněhu při dané teplotě. Automatizace KLIK může vzdáleně monitorovat a ovládat 1 hydrant KLIK nebo 1 000.",
    },
  ],
};
