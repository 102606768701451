import React from "react";
import Page from "../components/Page";
import styled from "../app/styled";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import Order from "../components/Order";

const ContactsPage = () => {
  return (
    <Page title="Kontakty">
      <ContactsRow>
        <MainContact>
          <Text fontSize="medium" color="orange" as="h2">
            NoHo Prague s.r.o.
          </Text>
          <Text>Meteorologická 792/29</Text>
          <Text>Praha 4</Text>
          <Text>140 00</Text>
          <br />
          <Text>
            <a href="tel:+420 775 233 292">+420 775 233 292</a>
          </Text>
          <Text>
            <a href="mailto:info@nohosnow.com">info@nohosnow.com</a>
          </Text>
          <br />
          <Text>IČ: 27610985</Text>
          <Text>DIČ: CZ27610985</Text>
          <br />
          <Text>Spisová značka: C 118768 vedená u Městského soudu v Praze</Text>
        </MainContact>
        <FormWrapper>
          <Order noWrap heading={"Kontaktujte nás"} />
        </FormWrapper>
      </ContactsRow>
    </Page>
  );
};

const ContactsRow = styled(Box).attrs({ isRow: true })`
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const MainContact = styled(Box)`
  flex: 0 0 48%;
`;

const FormWrapper = styled(Box)`
  flex: 0 0 48%;
`;

export default ContactsPage;
