import React, { useState } from "react";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import Break from "../base_components/Break";
import styled from "../app/styled";
import { Formik, Form } from "formik";
import StyledField from "../components/StyledField";
import Button from "../base_components/Button";
import emailjs from "emailjs-com";
import SpinnerLoader from "./Spinner";

const Order = (props: {
  textHolder?: string;
  noWrap?: boolean;
  heading?: string;
}) => {
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  if (emailSending && !emailSent) {
    return (
      <OrderRow>
        <TextBox>
          <OrderText>
            {props.heading ? props.heading : "Nezávazná poptávka"}
          </OrderText>
          <Break color="orange" width={30} />
        </TextBox>
        <FormWrapper noWrap={props.noWrap}>
          <SpinnerLoader />
        </FormWrapper>
      </OrderRow>
    );
  }

  if (emailSent) {
    return (
      <OrderRow>
        <TextBox>
          <OrderText>
            {props.heading ? props.heading : "Nezávazná poptávka"}
          </OrderText>
          <Break color="orange" width={30} />
        </TextBox>
        <FormWrapper noWrap={props.noWrap}>
          <SuccessText>Email byl odeslán. Děkujeme.</SuccessText>
        </FormWrapper>
      </OrderRow>
    );
  }
  return (
    <OrderRow>
      <TextBox>
        <OrderText>
          {props.heading ? props.heading : "Nezávazná poptávka"}
        </OrderText>
        <Break color="orange" width={30} />
      </TextBox>
      <FormWrapper noWrap={props.noWrap}>
        <Formik
          initialValues={{
            name: "",
            email: "",
            text: props.textHolder,
            phone: "",
          }}
          onSubmit={(values) => {
            console.log(values);

            setEmailSending(true);

            emailjs
              .send(
                "smtp_server",
                "template_sCAL3sn2",
                //@ts-ignore
                {
                  from_name: values.name,
                  from_email: values.email,
                  message_html: values.text,
                  phone_number: values.phone,
                },
                "user_qN5XkxYuMsl1CXHi3K4r9"
              )
              .then(
                (result: any) => {
                  console.log(result.text);
                  if (result.text === "OK") {
                    setEmailSent(true);
                  }
                },
                (error: any) => {
                  console.log(error.text);
                }
              );
          }}
        >
          {(props) => (
            <Form id="OrderForm">
              <StyledField
                id="name"
                name="name"
                placeholder="Vaše jméno"
                onChange={props.handleChange}
                type="text"
                value={props.values.name}
              />
              <StyledField
                id="email"
                name="email"
                placeholder="vas@email.cz"
                type="email"
                onChange={props.handleChange}
                value={props.values.email}
              />
              <StyledField
                id="phone"
                name="phone"
                onChange={props.handleChange}
                placeholder="+420 777 777 777"
                type="text"
                value={props.values.phone}
              />
              <StyledField
                id="text"
                name="text"
                as="textarea"
                onChange={props.handleChange}
                placeholder="Text Vaší zprávy"
                height={100}
                value={props.values.text}
              />
              <Button type="submit" selfAlign="flex-end">
                Odeslat
              </Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </OrderRow>
  );
};

const OrderRow = styled(Box).attrs({ isRow: true })`
  padding: ${(props) => props.theme.spacing.default};
  background: ${(props) => props.theme.colors.lightGrey};
  margin-top: ${(props) => props.theme.spacing.default};
  justify-content: center;
`;

const TextBox = styled(Box)`
  flex: 1 1 100%;
  align-items: center;
`;

const OrderText = styled(Text).attrs({ color: "orange" })`
  font-size: ${(props) => props.theme.fontSize.medium}px;
`;

const SuccessText = styled(Text).attrs({ color: "green" })`
  font-size: ${(props) => props.theme.fontSize.medium}px;
  align-self: center;
`;

const FormWrapper = styled(Box)<{ noWrap?: boolean }>`
  flex: 0 0 ${(props) => (props.noWrap ? 98 : 48)}%;

  @media ${(props) => props.theme.device.tablet} {
    flex: 0 0 98%;
  }
`;

export default Order;
