import React from "react";
import Page from "../components/Page";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import Heading from "../components/Heading";
import styled from "../app/styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const IndexPage = () => {
  return (
    <Page title="Komplexní řešení pro horská střediska" ownHeading>
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        <img
          src="https://res.cloudinary.com/dbisoc34u/image/upload/w_2000,ar_3:1,c_fill,g_auto,e_sharpen/v1595509201/header_diablo2_efhoau.jpg"
          alt="zasněžovací dělo"
        />
        <img
          src="https://res.cloudinary.com/dbisoc34u/image/upload/w_2000,ar_3:1,c_fill,g_auto,e_sharpen/v1595509201/header_fava-belt_rqz89p.jpg"
          alt="zasněžovací dělo"
        />
        <img
          src="https://res.cloudinary.com/dbisoc34u/image/upload/w_2000,ar_3:1,c_fill,g_auto,e_sharpen/v1595509201/header_halo_lmok72.jpg"
          alt="zasněžovací dělo"
        />
        <img
          src="https://res.cloudinary.com/dbisoc34u/image/upload/w_2000,ar_3:1,c_fill,g_auto,e_sharpen/v1595509202/header_impulse_hwtpiu.jpg"
          alt="zasněžovací dělo"
        />
      </Carousel>
      <Box>
        <Heading>Komplexní řešení pro horská střediska</Heading>
      </Box>
      <Box>
        <Text as="p">
          Přinášíme komplexní řešení pro horská střediska – od projektu, přes
          zajištění a supervizi realizace až po následný servis. Naší hlavní
          motivací je lepší výkon, provozní snadnost a spolehlivost námi
          dodávaných produktů a služeb.
        </Text>
        <br />
        <Text as="p">
          Pomůžeme Vám vytvářet lepší podmínky s vyšším podílem automatizace.
        </Text>
        <br />
        <Text as="p">
          Dodáváme energeticky efektivní technologie pro zasněžování.
        </Text>
      </Box>

      <Box>
        <br />
        <Text as="p" color={"orange"}>
          Specializujeme se na:
        </Text>
        <List>
          <ListItem>
            Technologické celky pro zasněžování
            <List>
              <ListItem>Zasněžovací děla</ListItem>
              <ListItem>Zasněžovací tyče</ListItem>
              <ListItem>Hydranty</ListItem>
              <ListItem>Zasněžovací hadice</ListItem>
              <ListItem>Camlocky a spojky</ListItem>
              <ListItem>Rozvody vody a vzduchu</ListItem>
              <ListItem>Čerpací a kompresorové stanice</ListItem>
            </List>
          </ListItem>
          <ListItem>Pojízdné koberce
            <List>
              <ListItem>Pro dětské ski parky, zábavní centra</ListItem>
              <ListItem>Nástupní koberce pro lanové dráhy</ListItem>
            </List>
          </ListItem>
          <ListItem>Vleky a lanové dráhy</ListItem>
          <ListItem>
            Lana pro lanové dráhy a vleky včetně instalací a zápletů
          </ListItem>
          <ListItem>Aditivum pro zasněžování</ListItem>
          <ListItem>
            Doplňky
            <List>
              <ListItem>Speciální rukavice</ListItem>
              <ListItem>Mobilní meteostanice</ListItem>
            </List>
          </ListItem>
          <ListItem>Zaškolení a tréninky práce s naší technikou</ListItem>
          <ListItem>
            Servis
            <List>
              <ListItem>Lanových drah a vleků</ListItem>
              <ListItem>Převodovek</ListItem>
              <ListItem>Lanových kotoučů</ListItem>
              <ListItem>Zasněžovací techniky</ListItem>
            </List>
          </ListItem>
        </List>
      </Box>
    </Page>
  );
};

const List = styled.ul`
  margin-left: ${(props) => props.theme.spacing.default};
  padding: ${(props) => props.theme.spacing.mini};
`;
const ListItem = styled.li`
  padding: ${(props) => props.theme.spacing.mini};
  font-size: ${(props) => props.theme.fontSize.normal}px;
  color: ${(props) => props.theme.text.color};
`;
export default IndexPage;
