import styled from '../app/styled';
import { colors, fontSize } from '../app/theme';

const Text = styled.span<{ color?: keyof typeof colors; fontSize?: keyof typeof fontSize; fontBolder?: boolean, isItalic?: boolean }>`
  margin: 0;
  padding: 0;
  line-height: ${({ theme }) => theme.text.lineHeight};
  font-size: ${props =>
    props.fontSize ? ({ theme }) => theme.fontSize[props.fontSize!] : ({ theme }) => theme.fontSize.normal}px;
  display: flex;
  font-style: ${props => props.isItalic ? "italic" : "normal"};
  font-weight: ${props => (props.fontBolder ? ({theme}) => theme.text.fontWeightBold : ({theme}) => theme.text.fontWeightNormal)};
  color: ${props => (props.color ? ({ theme }) => theme.colors[props.color!] : props.theme.text.color)};
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-family: ${props => props.theme.text.fontFamily};
  text-align: justify;
`;

export default Text;
