export const colors = {
  black: "#333",
  lightBlack: "#393737",
  white: "#FFFFFF",
  green: "#5bab8b",
  lightGrey: "#F6F6F6",
  grey: "#6e6e72",
  darkGrey: "#848383",
  pink: "#ffbcb1",
  lightPink: "#fbe8e5",
  red: "#ec2c69",
  orange: "#ef7b1a",
};

// Link to CSS filter generator to generate missing colors manualy
// add brightness(0) saturate(100%) manually to turn icon black
// https://codepen.io/sosuke/pen/Pjoqqp
export const cssFilterColors = {
  black:
    "brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);",
  lightBlack:
    "brightness(0) saturate(100%) invert(9%) sepia(1%) saturate(3496%) hue-rotate(314deg) brightness(108%) contrast(71%);",
  white:
    "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(60deg) brightness(103%) contrast(102%);",
  green:
    "brightness(0) saturate(100%) invert(66%) sepia(50%) saturate(333%) hue-rotate(104deg) brightness(83%) contrast(86%);",
  lightGrey:
    "brightness(0) saturate(100%) invert(99%) sepia(52%) saturate(179%) hue-rotate(282deg) brightness(115%) contrast(93%);",
  grey:
    "brightness(0) saturate(100%) invert(44%) sepia(5%) saturate(272%) hue-rotate(202deg) brightness(95%) contrast(85%);",
  darkGrey:
    "brightness(0) saturate(100%) invert(53%) sepia(1%) saturate(0%) hue-rotate(95deg) brightness(98%) contrast(83%);",
  pink:
    "brightness(0) saturate(100%) invert(74%) sepia(17%) saturate(613%) hue-rotate(319deg) brightness(101%) contrast(111%);",
  lightPink:
    "brightness(0) saturate(100%) invert(84%) sepia(5%) saturate(647%) hue-rotate(318deg) brightness(111%) contrast(97%);",
  red:
    "brightness(0) saturate(100%) invert(30%) sepia(36%) saturate(6899%) hue-rotate(325deg) brightness(95%) contrast(94%);",
  orange:
    "brightness(0) saturate(100%) invert(48%) sepia(96%) saturate(538%) hue-rotate(347deg) brightness(95%) contrast(96%);",
};

const spacing = {
  none: "0px",
  mini: "5px",
  small: "10px",
  default: "15px",
  medium: "20px",
  big: "30px",
  max: "45px",
  extreme: "90px",
};

const text = {
  color: colors.black,
  lineHeight: 1.6,
  fontFamily: "'Exo', sans-serif",
  fontFamilySecondary: "Playfair Display",
  fontWeightBold: "900",
  fontWeightNormal: "400",
};

export const fontSize = {
  supermini: 4,
  mini: 7,
  small: 11,
  normal: 16,
  medium: 19,
  bigger: 24,
  big: 32,
  max: 42,
};

const header = {
  desktopHeight: 70,
};

const border = {
  radius: 8,
};

const size = {
  mobile: '425px',
  tablet: '768px',
}

export const device = {

  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
};

export interface Theme {
  colors: { [key in keyof typeof colors]: string };
  cssFilterColors: { [key in keyof typeof colors]: string };
  spacing: { [key in keyof typeof spacing]: string | number };
  header: { [key in keyof typeof header]: number };
  text: {
    color: string;
    lineHeight: number;
    fontFamilySecondary: string;
    fontFamily: string;
    fontWeightBold: string;
    fontWeightNormal: string;
  };
  fontSize: { [key in keyof typeof fontSize]: number };
  border: { [key in keyof typeof border]: number };
  device: { [key in keyof typeof device]: string };
}

const theme: Theme = {
  colors,
  cssFilterColors,
  header,
  spacing,
  text,
  fontSize,
  border,
  device,
};

export { theme };

export default theme;
