import styled from '../app/styled';
import Box from './Box';
import { cloudimgBaseUrl } from './Image';
import Lazyload from './Lazyload';
import React from 'react';

const ImageCropped = (props: {
  size: number;
  src: string;
  ratio: number;
  position: 'TOP' | 'BOTTOM' | 'CENTER';
  imgItemProp?: string;
  name: string;
  noLazyload?: boolean;
}) => (
  <Lazyload height={props.size * props.ratio} disabled={props.noLazyload}>
    <Wrapper {...props}>
      <ImageWrapper>
        <Img alt={props.name} src={`${cloudimgBaseUrl}/width/${props.size}/x/${props.src}`} position={props.position} />
        {props.imgItemProp && <meta itemProp={props.imgItemProp} content={`${props.src}`} />}
      </ImageWrapper>
    </Wrapper>
  </Lazyload>
);

const Wrapper = styled(Box)<{ ratio: number }>`
  overflow: hidden !important;
  width: auto;
  padding-top: ${({ ratio }) => ratio * 100}%;
  position: relative;
`;

const ImageWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Img = styled.img<{ position: 'TOP' | 'BOTTOM' | 'CENTER' }>`
  line-height: 1;
  width: auto;
  object-fit: cover;
  height: 100%;
  object-position: ${({ position }) => position.toLocaleLowerCase()} center;
`;

export default ImageCropped;
