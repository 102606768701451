import React, { useState } from "react";
import Page from "../components/Page";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import Box from "../base_components/Box";
import Image from "../base_components/Image";
import Lazyload from "../base_components/Lazyload";
import ImageCropped from "../base_components/ImageCropped";
import styled from "../app/styled";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Carousel, { Modal, ModalGateway } from "react-images";
import ReactPlayer from "react-player";
import Order from "../components/Order";
import StyledMarkdown from "../components/StyledMarkdown";

const ProductPage = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const [product, setProduct] = useState<any>(false);
  const [productSlug, setProductSlug] = useState(match.params.slug);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isSentRequest, setIsSentRequest] = useState(false);

  if (productSlug !== match.params.slug) {
    setProduct(false);
    setProductSlug(match.params.slug);
  }

  if (isSentRequest === false && !product) {
    setIsSentRequest(true);
    fetch(`/api/products?slug=${match.params.slug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
      });
  }

  const navButtonStyles = (base: any) => ({
    ...base,
    backgroundColor: "white",
    boxShadow: "0 1px 6px rgba(0, 0, 0, 0.18)",
    color: "black",
    fill: "black",

    "&:hover, &:active": {
      backgroundColor: "white",
      color: "grey",
      opacity: 1,
    },
    "&:active": {
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.14)",
      transform: "scale(0.96)",
    },
  });

  if (!product) {
    return <Page title="Produkt se načítá" isLoading={true} isError={false} />;
  }

  return (
    <Page title={product?.name} description={product?.seoDescription}>
      <Breadcrumbs
        //@ts-ignore
        links={[
          { name: "Produkty", url: "/produkty" },
          {
            name: product?.category?.name,
            url: `/produkty/${product?.category?.slug}`,
          },
          {
            name: product?.name,
            url: `/produkt/${product?.slug}`,
          },
        ]}
      />
      <Box isRow>
        <ImageWrapper>
          <Image
            size={400}
            alt={product?.name}
            src={product?.photos[product.mainPhoto ? product.mainPhoto - 1 : 0]?.link}
          />
        </ImageWrapper>
        <TextWrapper>
          <StyledMarkdown source={product?.description} />
        </TextWrapper>
      </Box>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        style={{ paddingTop: "15px" }}
      >
        <Masonry gutter="20px">
          {product?.photos?.map((photo: any) => (
            <ImageItem
              onClick={() => {
                setCurrentImage(
                  product?.photos?.findIndex(
                    (search: any) => search.id === photo.id
                  )
                );
                setViewerIsOpen(true);
              }}
            >
              <ImageCropped
                size={400}
                ratio={1}
                position={"CENTER"}
                name={photo.link}
                src={photo.link}
              />
            </ImageItem>
          ))}
        </Masonry>
      </ResponsiveMasonry>

      {product.video && (
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          style={{ paddingTop: "15px" }}
        >
          <Masonry gutter="20px">
            <Lazyload height={300}>
              <ReactPlayer
                controls
                wrapper={Video}
                height="300px"
                width="100%"
                url={product.video}
              />
            </Lazyload>
          </Masonry>
        </ResponsiveMasonry>
      )}

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal
            onClose={() => {
              setCurrentImage(0);
              setViewerIsOpen(false);
            }}
          >
            <Carousel
              currentIndex={currentImage}
              //@ts-ignore
              views={product?.photos?.map((x) => ({
                src: x.link,
                caption: "Caption",
                source: x.link,
              }))}
              styles={{
                view: (base) => ({
                  ...base,

                  "& > img": {
                    margin: "0 auto",
                    height: "auto",
                    maxHeight: "100vh",
                  },
                }),
                navigationPrev: navButtonStyles,
                navigationNext: navButtonStyles,
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>

      <Order
        textHolder={`Dobrý den, rádi bychom získali další informace o produktu ${product?.name}.`}
      />
    </Page>
  );
};

const ImageWrapper = styled(Box)`
  flex: 0 0 40%;
`;

const TextWrapper = styled(Box)`
  flex: 0 0 55%;
`;

const Video = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;

  iframe {
    width: 100%;
  }
`;

const ImageItem = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.none}px;
  overflow: hidden;
  break-inside: avoid;
  position: relative;
  cursor: pointer;
`;

export default withRouter(ProductPage);
