import React from 'react';
import Spinner from '@atlaskit/spinner';
import styled from '../app/styled';

const SpinnerLoader = () => {

    return (
        <SpinnerWrapper>
            <Spinner size="xlarge" />
        </SpinnerWrapper>
    )
}

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SpinnerLoader;