import React from "react";
import styled from "../app/styled";
import Box from "../base_components/Box";
import Image from "../base_components/Image";
import Text from "../base_components/Text";
import { Link } from "react-router-dom";

const Category = (props: {
  name: string;
  slug: string;
  id: string;
  image: string;
}) => (
  <Containter>
    <StyledLink to={`/produkty/${props.slug}`} />
    <Image size={300} src={props.image} alt={props.name} />
    <Info>
      <Title>{props.name}</Title>
    </Info>
  </Containter>
);

const Containter = styled(Box)`
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.bigger}px;
  position: relative;
  margin: ${({ theme }) => theme.spacing.default};

  &:hover {
    & * {
      text-decoration: none !important;
    }
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  &:hover + img {
    transform: scale(1.1);
    opacity: 0.9;
  }
`;

const Info = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  bottom: 20px;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  padding: ${({ theme }) => theme.spacing.default};

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
  }
`;

const Title = styled(Text).attrs({ color: "white", fontSize: "bigger" })`
  font-size: ${(props) => props.theme.fontSize.bigger}px;
  text-align: center;

  @media only screen and (max-width: 1250px) {
    font-size: ${(props) => props.theme.fontSize.medium}px;
  }
`;

export default Category;
