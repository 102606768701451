import React from "react";
import Page from "../components/Page";
import styled from "../app/styled";
import Box from "../base_components/Box";
import Text from "../base_components/Text";
import Image from "../base_components/Image";

const BlizzardPage = () => {
  return (
    <Page
      title="Blizzard factory"
      description="Systém zasněžování za každého počasí"
    >
      <Box
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          fontSize="bigger"
          style={{ flex: 1, width: "50%", paddingRight: 20 }}
        >
          Blizzard Factory je nejsofistikovanější řešení pro zasněžování za
          každého počasí. Systém je nabitý funkcemi produkující sníh, který
          potřebujete, včetně neuvěřitelně kompaktního designu.
        </Text>
        <Box style={{ flex: 1, width: "50%" }}>
          <Image
            alt="blizzard"
            src="https://mnd.com/wp-content/uploads/2023/03/04-Shipping-Container-Mock-Up_blizzard-chalet.jpg.webp"
          />
        </Box>
      </Box>
      <Text
        fontSize="big"
        color="orange"
        style={{ justifyContent: "center", paddingBottom: 10 }}
        as="h2"
      >
        SILNÉ STRÁNKY BLIZZARD FACTORY
      </Text>
      <Box
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box style={{ flex: 1, width: "50%", paddingRight: 20 }}>
          <Text
            fontSize="bigger"
            color="orange"
            style={{ justifyContent: "center", paddingBottom: 10 }}
            as="h3"
          >
            #1 Velmi snadná údržba
          </Text>
          <Text>
            Blizard Faktory používat chladivo nové generace, které je méně
            škodlivé pro životní prostředí a snáze se používá a udržuje. Na
            rozdíl od hořlavého a žíravého čpavku, se kterým mohou manipulovat
            pouze speciálně vyškolení lidé.
          </Text>
        </Box>
        <Box style={{ flex: 1, width: "50%" }}>
          <Image
            alt="blizzard"
            src="https://mnd.com/wp-content/uploads/2023/03/Miniature-video-blizzard.jpg"
          />
        </Box>
      </Box>

      <Box
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box style={{ flex: 1, width: "50%" }}>
          <Image
            src="https://mnd.com/wp-content/uploads/2023/03/Photos-produits-blizzard-2.jpg.webp"
            alt="blizzard"
          />
        </Box>
        <Box style={{ flex: 1, width: "50%", paddingLeft: 20 }}>
          <Text
            fontSize="bigger"
            color="orange"
            style={{ justifyContent: "center", paddingBottom: 10 }}
            as="h3"
          >
            #2 Kompaktní design poskytující nadměrný výkon zasněžování
          </Text>
          <Text>
            Blizzard Factory lze snadno ovládat a instalovat díky své nízké
            půdorysné ploše. Jeho malá velikost nic neubírá na schopnostech
            velké produkce sněhu. Součástí řešení je automatický systém pro
            efektivnější rozptýlení sněhu.
          </Text>
        </Box>
      </Box>
      <Box
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box style={{ flex: 1, width: "50%", paddingRight: 20 }}>
          <Text
            fontSize="bigger"
            color="orange"
            style={{ justifyContent: "center", paddingBottom: 10 }}
            as="h3"
          >
            #3 Systém rekuperace tepla
          </Text>
          <Text>
            Volitelný systém rekuperace tepla generuje ekvivalent až 180 kW
            tepelné energie. Nejen, že je tento systém ideální pro vytápění
            kanceláří, garáží a výrobních místností, ale také pomáhá zajistit
            větší návratnost vašeho zasněžovacího řešení.
          </Text>
        </Box>
        <Box style={{ flex: 1, width: "50%" }}>
          <Image
            src="https://mnd.com/wp-content/uploads/2023/03/Benef-produits-blizzard.jpg"
            alt="blizzard"
          />
        </Box>
      </Box>
    </Page>
  );
};

const ContactsRow = styled(Box).attrs({ isRow: true })`
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const MainContact = styled(Box)`
  flex: 0 0 48%;
`;

const FormWrapper = styled(Box)`
  flex: 0 0 48%;
`;

export default BlizzardPage;
