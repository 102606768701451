import React from "react";
import Page from "../components/Page";
import Box from "../base_components/Box";
import Text from "../base_components/Text";

const AboutPage = () => {
  return (
    <Page title="O nás">
      <Box>
        <Text as="p">
          Naše společnost působí na středoevropském trhu od roku 2006. Za tuto
          dobu jsme se vypracovali mezi přední řešitele snowmakingu a dodavatele
          špičkových technologií pro lyžařská střediska. Pro naše zákazníky jsme
          stálým partnerem, se kterým lze konzultovat a řešit provozní i
          projekční problematiku provozovatelů zimních středisek i bez smluvního
          závazku.
        </Text>
        <br />
        <Text as="p">
          Náš stabilní tým zaměstnanců a externích spolupracovníků s dlouholetou
          praxí je schopen velice rychle a efektivně řešit požadavky zákazníků v
          zimních střediscích od záměru, studie, projektu až po realizaci včetně
          veškerých inženýrských činností a následně pak zajištění záručních a
          pozáručních servisů.
        </Text>
        <br />
        <Text as="p">
          Námi dodávaná technologie umožňuje optimální výkon a výrobu kvalitního
          sněhu v nejširším možném rozsahu teplot. Je určena na pokrytí sněhem
          nejdříve otevřených částí lyžařských středisek. Jsme schopni Vám
          zabezpečit výrobu sněhu s nízkým průtokem vody ve vysokých teplotách s
          hladkým přechodem k vyššímu průtoku při nízkých teplotách. Námi
          dodávaná zařízení se vyznačují jednoduchou konstrukcí, vysokým výkonem
          a bezchybnou funkčností. Všechny výrobky jsou testovány v nejtvrdších
          Kanadských podmínkách.
        </Text>
      </Box>
    </Page>
  );
};

export default AboutPage;
